<template>
  <div id="codeMirrorWrp">
    <codemirror
      ref="codeMirror"
      :value="value"
      :options="options"
      @input="codeChange"
    />
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
// mode
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/sql/sql";
// 主题配置
import "codemirror/theme/ayu-dark.css";
import "codemirror/theme/blackboard.css";
import "codemirror/theme/monokai.css";
import "codemirror/theme/base16-light.css";
import "codemirror/theme/idea.css";

export default {
  name: "CodeMirror",
  components: {
    codemirror,
  },
  data() {
    return {};
  },
  props: {
    value: { type: String },
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    codeChange(newCode) {
      this.$emit("onCodeChange", newCode);
    },
    getValue() {
      const { codemirror } = this.$refs["codeMirror"];
      return codemirror.getTextArea();
    },
  },
};
</script>

<style lang="less" scoped>
#codeMirrorWrp {
  width: 100%;
  height: 100%;
  background-color: #fff;
  /deep/ .vue-codemirror {
    height: 100%;
    background-color: #fff;
    .CodeMirror {
      height: 100%;
    }
  }
}
</style>
