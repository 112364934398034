<template>
  <div class="page-wrp">
    <a-space class="action-wrp">
      <a-button type="link" @click="cancel">
        <a-icon type="left" />返回
      </a-button>
      <a-space>
        <a-button type="primary" @click="save">保存</a-button>
        <a-button type="danger" @click="del">删除</a-button>
        <a-button @click="cancel">取消</a-button>
      </a-space>
    </a-space>
    <div class="codeMirror-wrp">
      <CodeMirror
        :value="value"
        :options="options"
        @onCodeChange="onCodeChange"
      />
    </div>
  </div>
</template>

<script>
import CodeMirror from "@/components/CodeMirror";
import {
  sqlScriptList,
  sqlScriptAdd,
  sqlScriptUpdate,
  sqlScriptDel,
  redisScriptList,
  redisScriptAdd,
  redisScriptUpdate,
  redisScriptDel,
} from "@/apis/script";
import { Modal, notification } from "ant-design-vue";

export default {
  components: { CodeMirror },
  data() {
    return {
      scriptObj: {},
      value: "",
      options: {
        tabSize: 2,
        mode: "x-sql",
        theme: "ayu-dark",
        lineNumbers: true,
        line: true,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const {
        query: { type, id },
      } = this.$route;
      if (type === "SQL") {
        this.options.mode = "sql";
        sqlScriptList({ interfaceId: id }).then((res) => {
          const {
            code,
            data: { resList },
          } = res.data;
          if (code === "200") {
            if (!this.$_.isEmpty(resList)) {
              this.scriptObj = resList[0];
              this.value = resList[0]?.sqlScriptCode;
            }
          }
        });
      } else if (type === "REDIS") {
        this.options.mode = "sql";
        redisScriptList({ interfaceId: id }).then((res) => {
          const {
            code,
            data: { resList },
          } = res.data;
          if (code === "200") {
            if (!this.$_.isEmpty(resList)) {
              this.scriptObj = resList[0];
              this.value = resList[0]?.redisScriptCode;
            }
          }
        });
      }
    },
    onCodeChange(newCode) {
      this.value = newCode;
    },
    save() {
      const {
        query: { type, id },
      } = this.$route;
      if (type === "SQL") {
        if (this.scriptObj.sqlScriptId) {
          // eidt
          sqlScriptUpdate({
            sqlScriptId: this.scriptObj.sqlScriptId,
            sqlScriptCode: this.value,
            sqlScriptState: "1",
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              notification.success({
                message: "保存成功",
              });
              this.cancel();
            }
          });
        } else {
          // add
          sqlScriptAdd({
            sqlScriptCode: this.value,
            interfaceId: id,
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              notification.success({
                message: "保存成功",
              });
              this.cancel();
            }
          });
        }
      } else {
        if (this.scriptObj.redisScriptId) {
          // eidt
          redisScriptUpdate({
            redisScriptId: this.scriptObj.redisScriptId,
            redisScriptCode: this.value,
            redisScriptState: "1",
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              notification.success({
                message: "保存成功",
              });
              this.cancel();
            }
          });
        } else {
          // add
          redisScriptAdd({
            redisScriptCode: this.value,
            interfaceId: id,
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              notification.success({
                message: "保存成功",
              });
              this.cancel();
            }
          });
        }
      }
    },
    del() {
      Modal.confirm({
        title: `确定要删除该脚本吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          const {
            query: { type },
          } = this.$route;
          if (type === "SQL") {
            sqlScriptDel({ sqlScriptId: this.scriptObj.sqlScriptId }).then(
              (res) => {
                const { code } = res.data;
                if (code === "200") {
                  notification.success({
                    message: "删除成功",
                  });
                  this.cancel();
                }
              }
            );
          } else {
            redisScriptDel({
              redisScriptId: this.scriptObj.redisScriptId,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                notification.success({
                  message: "删除成功",
                });
                this.cancel();
              }
            });
          }
        },
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/interface/list",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page-wrp {
  height: 100%;
  .action-wrp {
    display: flex;
    justify-content: space-between;
  }
  .codeMirror-wrp {
    height: calc(100% - 52px);
    margin-top: 20px;
  }
}
</style>
