import request from "@/utils/request";

function sqlScriptList(data) {
  return request({
    url: "/show/sqlScript/list",
    method: "post",
    data: data,
  });
}

function sqlScriptAdd(data) {
  return request({
    url: "/show/sqlScript/add",
    method: "post",
    data: data,
  });
}

function sqlScriptUpdate(data) {
  return request({
    url: "/show/sqlScript/update",
    method: "post",
    data: data,
  });
}

function sqlScriptDel(data) {
  return request({
    url: "/show/sqlScript/del",
    method: "post",
    data: data,
  });
}

function redisScriptList(data) {
  return request({
    url: "/show/redisScript/list",
    method: "post",
    data: data,
  });
}

function redisScriptAdd(data) {
  return request({
    url: "/show/redisScript/add",
    method: "post",
    data: data,
  });
}

function redisScriptUpdate(data) {
  return request({
    url: "/show/redisScript/update",
    method: "post",
    data: data,
  });
}

function redisScriptDel(data) {
  return request({
    url: "/show/redisScript/del",
    method: "post",
    data: data,
  });
}

export {
  sqlScriptList,
  sqlScriptAdd,
  sqlScriptUpdate,
  sqlScriptDel,
  redisScriptList,
  redisScriptAdd,
  redisScriptUpdate,
  redisScriptDel,
};
